import store from "@/state/store";

export default [
  //login
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
          next();
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  //forgot password
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the forgot password page
          next();
        }
      },
    },
  },
  //reset password
  {
    path: "/reset-password",
    name: "Reset password",
    component: () => import("../views/account/create-password.vue"),
    meta: {
      title: "Reset Account Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the forgot password page
          next();
        }
      }
    },
  },
  //logout
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/account/logout.vue"),
    meta: {
      title: "Logout",
      beforeResolve(routeTo, routeFrom, next) {

        localStorage.removeItem("user");
        localStorage.removeItem("userid");
        localStorage.removeItem("jwt");

        next();
      },
    },
  },
  //default dashboard
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/default/index.vue"),
  },
  //client management - client
  {
    path: "/client",
    name: "client",
    meta: {
      title: "Client",
      authRequired: true,
    },
    component: () => import("../views/client/client.vue"),
  },
  //client management - site
  {
    path: "/client/site",
    name: "Site",
    meta: {
      title: "Site",
      authRequired: true,
    },
    component: () => import("../views/client/site.vue"),
  },
  //client management - block
  {
    path: "/client/block",
    name: "Block",
    meta: {
      title: "Block",
      authRequired: true,
    },
    component: () => import("../views/client/block.vue"),
  },
  //client management - area
  {
    path: "/client/area",
    name: "Area",
    meta: {
      title: "Area",
      authRequired: true,
    },
    component: () => import("../views/client/area.vue"),
  },
  //client management - location
  {
    path: "/client/location",
    name: "Location",
    meta: {
      title: "Location",
      authRequired: true,
    },
    component: () => import("../views/client/location.vue"),
  },
  //client management - location qr viewer
  {
    path: "/client/location-qr-viewer",
    name: "Room QR Code Viewer",
    meta: {
      title: "Room QR Code Viewer",
      authRequired: true,
    },
    component: () => import("../views/client/location-qr-viewer.vue"),
  },
  //user management - role
  {
    path: "/user/role",
    name: "Role",
    meta: {
      title: "Role",
      authRequired: true,
    },
    component: () => import("../views/user/role.vue"),
  },
  //user management - dormitory
  {
    path: "/user/dormitory",
    name: "Dormitory",
    meta: {
      title: "Dormitory",
      authRequired: true,
    },
    component: () => import("../views/user/dormitory.vue"),
  },
  //user management - team
  {
    path: "/user/team",
    name: "Team",
    meta: {
      title: "Team",
      authRequired: true,
    },
    component: () => import("../views/user/team.vue"),
  },
  //user management - department
  {
    path: "/user/department",
    name: "Department",
    meta: {
      title: "Department",
      authRequired: true,
    },
    component: () => import("../views/user/department.vue"),
  },
  //user management - user
  {
    path: "/user/employee",
    name: "User",
    meta: {
      title: "User",
      authRequired: true,
    },
    component: () => import("../views/user/employee.vue"),
  },
  //pet management - pet cage
  {
    path: "/pet/cage",
    name: "Pet Cage",
    meta: {
      title: "Pet Cage",
      authRequired: true,
    },
    component: () => import("../views/pet/pet-cage.vue"),
  },
  //pet management - pet 
  {
    path: "/pet",
    name: "Pet",
    meta: {
      title: "Pet",
      authRequired: true,
    },
    component: () => import("../views/pet/pet.vue"),
  },
  //pet management - weight 
  {
    path: "/pet/weight",
    name: "Pet Weight",
    meta: {
      title: "Pet Weight",
      authRequired: true,
    },
    component: () => import("../views/pet/weight.vue"),
  },
  //pet management - weight 
  {
    path: "/pet/weight-summary",
    name: "Pet Weight Summary",
    meta: {
      title: "Pet Weight Summary",
      authRequired: true,
    },
    component: () => import("../views/pet/pet-weight-summary.vue"),
  },
  //pet management - pellet consumption 
  {
    path: "/pet/pellet-consumption",
    name: "Pet Pellet Consumption",
    meta: {
      title: "Pet Pellet Consumption",
      authRequired: true,
    },
    component: () => import("../views/pet/pellet-consumption.vue"),
  },
  //pet management - pellet consumption 
  {
    path: "/pet/pellet-consumption-pet-view",
    name: "Pet Pellet Consumption (Pet View)",
    meta: {
      title: "Pet Pellet Consumption (Pet View)",
      authRequired: true,
    },
    component: () => import("../views/pet/pellet-consumption-pet-view.vue"),
  },

  //attedance management - attendance listing
  {
    path: "/attendance/listing",
    name: "Attendance",
    meta: {
      title: "Attendance",
      authRequired: true,
    },
    component: () => import("../views/attendance/listing.vue"),
  },
  //attedance management - summary report
  {
    path: "/attendance/summary-report",
    name: "Attendance Summary Report",
    meta: {
      title: "Attendance Summary Report",
      authRequired: true,
    },
    component: () => import("../views/attendance/summary-report.vue"),
  },
  //attedance management - summary report
  {
    path: "/attendance/att-stn-summary",
    name: "Attendance Station Summary",
    meta: {
      title: "Attendance Station Summary",
      authRequired: true,
    },
    component: () => import("../views/attendance/att-stn-summary.vue"),
  },
  //location tracking - records
  {
    path: "/administrative/location-track",
    name: "Location Tracking Records",
    meta: {
      title: "Location Tracking Records",
      authRequired: true,
    },
    component: () => import("../views/administrative/location-track.vue"),
  },
  //room entry - listing
  {
    path: "/room-entry/logs",
    name: "Room Entry Logs",
    meta: {
      title: "Room Entry Logs",
      authRequired: true,
    },
    component: () => import("../views/room-entry/listing.vue"),
  },
  //room entry - summary report
  {
    path: "/room-entry/summary-report",
    name: "Room Entry Summary Report",
    meta: {
      title: "Room Entry Summary Report",
      authRequired: true,
    },
    component: () => import("../views/room-entry/report-viewer.vue"),
  },
  //tvf room access - topic
  {
    path: "/tvf/topic",
    name: "TVF Room Access Topic",
    meta: {
      title: "TVF Room Access Topic",
      authRequired: true,
    },
    component: () => import("../views/tvf-room-access/topic.vue"),
  },
  //tvf room access - logs
  {
    path: "/tvf/logs",
    name: "TVF Room Access Logs",
    meta: {
      title: "TVF Room Access Logs",
      authRequired: true,
    },
    component: () => import("../views/tvf-room-access/logs.vue"),
  },
  //tvf room access - log
  {
    path: "/tvf/log-viewer",
    name: "TVF Room Access Log",
    meta: {
      title: "TVF Room Access Log",
    },
    component: () => import("../views/tvf-room-access/log-viewer.vue"),
  },
  //tvf room access - group
  {
    path: "/tvf/groups",
    name: "TVF Room Access Groups",
    meta: {
      title: "TVF Room Access Groups",
      authRequired: true,
    },
    component: () => import("../views/tvf-room-access/groups.vue"),
  },
  //tvf room access - summary report
  {
    path: "/tvf/summary-report",
    name: "TVF Room Access Summary Report",
    meta: {
      title: "TVF Room Access Summary Report",
      authRequired: true,
    },
    component: () => import("../views/tvf-room-access/summary-report.vue"),
  },
  //toolbox meeting management - topic
  {
    path: "/tm/topic",
    name: "Toolbox Meeting Topic",
    meta: {
      title: "Toolbox Meeting Topic",
      authRequired: true,
    },
    component: () => import("../views/toolbox-meeting/topic.vue"),
  },
  //toolbox meeting management - reports
  {
    path: "/tm/reports",
    name: "Toolbox Meeting Reports",
    meta: {
      title: "Toolbox Meeting Reports",
      authRequired: true,
    },
    component: () => import("../views/toolbox-meeting/reports.vue"),
  },
  //toolbox meeting management - report
  {
    path: "/tm/report",
    name: "Toolbox Meeting Report",
    meta: {
      title: "Toolbox Meeting Report",
      authRequired: false,
    },
    component: () => import("../views/toolbox-meeting/report.vue"),
  },
  //toolbox meeting management - report viewer
  {
    path: "/tm/report-viewer",
    name: "Toolbox Meeting Report",
    meta: {
      title: "Toolbox Meeting Report"
    },
    component: () => import("../views/toolbox-meeting/reportviewer.vue"),
  },
  //equipment management - equipments
  {
    path: "/iv/equipments",
    name: "Equipment Management",
    meta: {
      title: "Equipment Management",
      authRequired: true,
    },
    component: () => import("../views/equipment/listing.vue"),
  },
  //equipment management - import
  {
    path: "/equipment/import",
    name: "Equipment Import",
    meta: {
      title: "Equipment Import",
      authRequired: true,
    },
    component: () => import("../views/equipment/import.vue"),
  },
  //supplier
  {
    path: "/supplier",
    name: "Supplier",
    meta: {
      title: "Supplier",
      authRequired: true,
    },
    component: () => import("../views/equipment/supplier.vue"),
  },
  //equipment management - monitoring
  {
    path: "/equipment/monitoring",
    name: "Equipment Monitoring",
    meta: {
      title: "Equipment Monitoring",
      authRequired: true,
    },
    component: () => import("../views/equipment/monitoring.vue"),
  },
  //material management - category
  {
    path: "/material/category",
    name: "Material Category",
    meta: {
      title: "Material Category",
      authRequired: true,
    },
    component: () => import("../views/material/category.vue"),
  },
  //material management - sub-category
  {
    path: "/material/subcategory",
    name: "Material Subcategory",
    meta: {
      title: "Material Subcategory",
      authRequired: true,
    },
    component: () => import("../views/material/subcategory.vue"),
  },
  //material management - material
  {
    path: "/material/item",
    name: "Material",
    meta: {
      title: "Material",
      authRequired: true,
    },
    component: () => import("../views/material/material.vue"),
  },
  //material management - material item QR code viewer
  {
    path: "/material/item-viewer",
    name: "Material Item QR Code Viewer",
    meta: {
      title: "Material Item QR Code Viewer"
    },
    component: () => import("../views/material/item-viewer.vue"),
  },
  //material management - import
  {
    path: "/material/import",
    name: "Material Import",
    meta: {
      title: "Material Import",
      authRequired: true,
    },
    component: () => import("../views/material/import.vue"),
  },
  //material management - material orders
  {
    path: "/material/orders",
    name: "Material Orders",
    meta: {
      title: "Material Orders",
      authRequired: true,
    },
    component: () => import("../views/material/orders.vue"),
  },
  //material management - material order viewer
  {
    path: "/material/order-viewer",
    name: "Material Order",
    meta: {
      title: "Material Order"
    },
    component: () => import("../views/material/orderviewer.vue"),
  },
  //material management - approval orders
  {
    path: "/material/orders-approval",
    name: "Pending Approval Orders",
    meta: {
      title: "Pending Approval Orders",
      authRequired: true,
    },
    component: () => import("../views/material/orders-approval.vue"),
  },
  //material management - in pending stock orders
  {
    path: "/material/orders-pending-stock",
    name: "In Pending Stock Orders",
    meta: {
      title: "In Pending Stock Orders",
      authRequired: true,
    },
    component: () => import("../views/material/orders-pending-stock.vue"),
  },
  //material management - in progress / ready orders
  {
    path: "/material/orders-progress-ready",
    name: "Prepare Order Item",
    meta: {
      title: "Prepare Order Item",
      authRequired: true,
    },
    component: () => import("../views/material/orders-progress-ready.vue"),
  },
  //notification management - email
  {
    path: "/notification/email",
    name: "Email Notification",
    meta: {
      title: "Email Notification",
      authRequired: true,
    },
    component: () => import("../views/notification/email.vue"),
  },
  //courier service - requests
  {
    path: "/courier-service/requests",
    name: "Request Status",
    meta: {
      title: "Request Status",
      authRequired: true,
    },
    component: () => import("../views/courier-service/requests.vue"),
  },
  //courier service - viewer
  {
    path: "/courier-service/viewer",
    name: "Courier Service Viewer",
    meta: {
      title: "Courier Service Viewer"
    },
    component: () => import("../views/courier-service/viewer.vue"),
  },
  //courier service - pending transit
  {
    path: "/courier-service/transit",
    name: "Pending Transit",
    meta: {
      title: "Pending Transit",
      authRequired: true,
    },
    component: () => import("../views/courier-service/transit.vue"),
  },
  //courier service - pending receive
  {
    path: "/courier-service/receive",
    name: "Pending Receive",
    meta: {
      title: "Pending Receive",
      authRequired: true,
    },
    component: () => import("../views/courier-service/receive.vue"),
  },
  //courier service - active monitoring
  {
    path: "/courier-service/active-monitoring",
    name: "Active Monitoring",
    meta: {
      title: "Active Monitoring",
      authRequired: true,
    },
    component: () => import("../views/courier-service/active-monitoring.vue"),
  },
  //site survey - reports
  {
    path: "/site-survey/reports",
    name: "Site Survey Reports",
    meta: {
      title: "Site Survey Reports",
      authRequired: true,
    },
    component: () => import("../views/site-survey/reports.vue"),
  },
  //site survey - report viewer
  {
    path: "/site-survey/report-viewer",
    name: "Site Survey Report Viewer",
    meta: {
      title: "Site Survey Report Viewer"
    },
    component: () => import("../views/site-survey/report-viewer.vue"),
  },
  //site work - reports
  {
    path: "/site-work/reports",
    name: "Site Work Reports",
    meta: {
      title: "Site Work Reports",
      authRequired: true,
    },
    component: () => import("../views/site-work/reports.vue"),
  },
  //site work - report viewer
  {
    path: "/site-work/report-viewer",
    name: "Site Work Report Viewer",
    meta: {
      title: "Site Work Report Viewer"
    },
    component: () => import("../views/site-work/report-viewer.vue"),
  },
  //third party - defect reports
  {
    path: "/third-party/defects",
    name: "TP Defect Reports",
    meta: {
      title: "TP Defect Reports",
      authRequired: true,
    },
    component: () => import("../views/third-party/defects.vue"),
  },
  //third party - report viewer
  {
    path: "/third-party/defect-viewer",
    name: "TP Defect Report Viewer",
    meta: {
      title: "TP Defect Report Viewer"
    },
    component: () => import("../views/third-party/defect-viewer.vue"),
  },
  //pre check - reports
  {
    path: "/pre-check/reports",
    name: "Pre-Check Reports",
    meta: {
      title: "Pre-Check Reports",
      authRequired: true,
    },
    component: () => import("../views/pre-check/reports.vue"),
  },
  //pre check - report viewer
  {
    path: "/pre-check/report-viewer",
    name: "Pre-Check Report Viewer",
    meta: {
      title: "Pre-Check Report Viewer"
    },
    component: () => import("../views/pre-check/report-viewer.vue"),
  },

  //site temperature test - reports
  {
    path: "/site-temp/reports",
    name: "Site Temp Test Reports",
    meta: {
      title: "Site Temp Test Reports",
      authRequired: true,
    },
    component: () => import("../views/site-temp/reports.vue"),
  },

  //site temperature test - report viewer
  {
    path: "/site-temp/report-viewer",
    name: "Site Temp Test Report Viewer",
    meta: {
      title: "Site Temp Test Report Viewer"
    },
    component: () => import("../views/site-temp/report-viewer.vue"),
  },

  //engineer checklist - NCP - WIT reports
  {
    path: "/engineer-checklist/ncp/wits",
    name: "NCP - WIT Reports",
    meta: {
      title: "NCP - WIT Reports",
      authRequired: true,
    },
    component: () => import("../views/engineer-checklist/ncp/wits.vue"),
  },

  //engineer checklist - NCP - LBC reports
  {
    path: "/engineer-checklist/ncp/lbcs",
    name: "NCP - LBC Reports",
    meta: {
      title: "NCP - LBC Reports",
      authRequired: true,
    },
    component: () => import("../views/engineer-checklist/ncp/lbcs.vue"),
  },

  //engineer checklist - NCP - HPC reports
  {
    path: "/engineer-checklist/ncp/hpcs",
    name: "NCP - HPC Reports",
    meta: {
      title: "NCP - HPC Reports",
      authRequired: true,
    },
    component: () => import("../views/engineer-checklist/ncp/hpcs.vue"),
  },

  //engineer checklist - NCP - PM reports
  {
    path: "/engineer-checklist/ncp/pm/reports",
    name: "NCP - PM Reports",
    meta: {
      title: "NCP - PM Reports",
      authRequired: true,
    },
    component: () => import("../views/engineer-checklist/ncp/pm/reports.vue"),
  },

  //engineer checklist - NCP - PM report viewer
  {
    path: "/engineer-checklist/ncp/pm/report-viewer",
    name: "NCP - PM Report Viewer",
    meta: {
      title: "NCP - PM Report Viewer"
    },
    component: () => import("../views/engineer-checklist/ncp/pm/report-viewer.vue"),
  },
  
  //engineer checklist - NCP - Water sampling reports
  {
    path: "/engineer-checklist/water-sampling-reports/:type",
    name: "NCP - Water Sampling Reports",
    meta: {
      title: "NCP - Water Sampling Reports",
      authRequired: true,
    },
    component: () => import("../views/engineer-checklist/engineer-reports.vue?typeId=2"),
  },
  //engineer checklist - NCP - Legionella reports
  {
    path: "/engineer-checklist/legionella-reports/:type",
    name: "NCP - Legionella Reports",
    meta: {
      title: "NCP - Legionella Reports",
      authRequired: true,
    },
    component: () => import("../views/engineer-checklist/engineer-reports.vue?typeId=3"),
  },
  //engineer checklist - NCP - Disinfectant reports
  {
    path: "/engineer-checklist/disinfentant-reports/:type",
    name: "NCP - Disinfectant Reports",
    meta: {
      title: "NCP - Disinfectant Reports",
      authRequired: true,
    },
    component: () => import("../views/engineer-checklist/engineer-reports.vue?typeId=4"),
  },
  //engineer checklist - NCP - Cyclone filter reports
  {
    path: "/engineer-checklist/cyclone-filter-reports/:type",
    name: "NCP - Cyclone Filter Reports",
    meta: {
      title: "NCP - Cyclone Filter Reports",
      authRequired: true,
    },
    component: () => import("../views/engineer-checklist/engineer-reports.vue?typeId=5"),
  },
  //engineer checklist - NCP - Chamber reports
  {
    path: "/engineer-checklist/chamber-reports/:type",
    name: "NCP - Chamber Reports",
    meta: {
      title: "NCP - Chamber Reports",
      authRequired: true,
    },
    component: () => import("../views/engineer-checklist/engineer-reports.vue?typeId=6"),
  },

  //engineer checklist - report viewer
  {
    path: "/engineer-checklist/report-viewer",
    name: "Engineer Report Viewer",
    meta: {
      title: "Engineer Report Viewer"
    },
    component: () => import("../views/engineer-checklist/engineer-report-viewer.vue"),
  },
  

  {
    path: "/coming-soon",
    name: "Coming Soon",
    meta: {
      title: "Coming Soon",
      authRequired: true,
    },
    component: () => import("../views/pages/coming-soon.vue"),
  },
  {
    path: '/auth/404',
    name: '404',
    meta: {
      title: "Error 404",
    },
    component: () => import('../views/auth/errors/404')
  },
  {
    path: '/auth/500',
    name: '500',
    meta: {
      title: "Error 500", authRequired: true,
    },
    component: () => import('../views/auth/errors/500')
  },
  {
    path: '/auth/404-basic',
    name: '404-basic',
    meta: {
      title: "Error 404",
    },
    component: () => import('../views/auth/errors/404-basic')
  },
  {
    path: '/auth/404-cover',
    name: '404-cover',
    meta: {
      title: "Error 404"
    },
    component: () => import('../views/auth/errors/404-cover')
  },
  {
    path: '/auth/ofline',
    name: 'ofline',
    meta: {
      title: "Offline", authRequired: true,
    },
    component: () => import('../views/auth/errors/ofline')
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    meta: {
      title: "Error 404",
    },
    component: () => import('../views/auth/errors/404-basic')
  },
];